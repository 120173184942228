import * as conatant_types from '../contants/device_type';
import { toastError, toastSuccess } from '../helpers/toastHelpers';
const initialState =
{
    data_table_device_types: [],
    data_pagination_device_types: 0,
    data_create: null,
    initialValues: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case conatant_types.DEVICE_TYPE_FETCH_LIST_SUCCESS: {
            console.log('DEVICE_TYPE_FETCH_LIST_SUCCESS')
            const { data } = action.payload;
            return {
                ...state,
                initialValues: null,
                data_table_device_types: data.data,
                data_pagination_device_types: data.count.length > 0 ? data.count[0].count : null
            };
        }
        case conatant_types.DEVICE_TYPE_FETCH_ITEM_BY_ID_SUCCESS: {
            const { data } = action.payload;
            console.log('data', data)
            return {
                ...state,
                initialValues: data
            };
        }
        case conatant_types.DEVICE_TYPE_CREATE_UPDATE_SUCCESS: {
            const { data } = action.payload;
            const { status } = data;
            if (!status) {
                toastError(data.message)
            } else {
                toastSuccess("Tạo thông tin thành công.")
                return { ...state, data_create: data };
            }
            return { ...state };
        }
        case conatant_types.DEVICE_TYPE_DELETE_SUCCESS: {
            const { data } = action.payload;
            console.log(data)
            const { status } = data;
            if (!status) {
                toastError(data.message)
            } else {
                const { resp } = data;
                toastSuccess("Đã xóa thành công.")
                let { data_table_device_types } = state;
                const index = data_table_device_types.findIndex(item => item._id == resp._id);
                data_table_device_types.splice(index, 1);
                return { ...state, data_table_device_types: [...data_table_device_types] };
            }
            console.log('data', data)
            return { ...state };
        }
        case conatant_types.DEVICE_TYPE_UPDATE_SUCCESS: {
            const { data } = action.payload;
            const { status } = data;
            if (!status) {
                toastError(data.message)
            } else {
                toastSuccess("Thay đổi thông tin thành công.")
            }
            return {
                ...state
            };
        }
        default:
            return state;
    }
};

export default reducer;
