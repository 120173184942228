import { call, put, delay, takeEvery } from 'redux-saga/effects';
import { STATUS_CODE } from '../contants';

import * as authenTypes from '../contants/auth';

import {
   handleLoginSuccess,
   handleLoginFailed,
   handleSendMailResetSuccess,
   handleResetPassSuccess
} from '../actions/auth';

import { handleLoginAPI } from '../apis/auth';
import { apiPost } from '../apis/common';
function* handleLoginSaga({ payload }) {
   const { email, password } = payload;
   const resp = yield call(handleLoginAPI, { email, password });
   const { data, status } = resp;
   if (status === STATUS_CODE.SUSSESS) {
      yield put(handleLoginSuccess(data));
   } else {
      yield put(handleLoginFailed(data))
   }
   yield delay(500);
}
function* handleSendMailSaga({ payload }) {
   const resp = yield call(apiPost, 'auth/send-mail-reset', payload);
   const { data, status } = resp;
   if (status === STATUS_CODE.SUSSESS) {
      yield put(handleSendMailResetSuccess(data));
   }
}
function* handleResetPassSaga({ payload }) {
   const resp = yield call(apiPost, 'auth/reset-pass', payload);
   console.log('handleResetPassSaga', resp)
   const { data, status } = resp;
   if (status === STATUS_CODE.SUSSESS) {
      yield put(handleResetPassSuccess(data));
   }
}



function* handleAuthen() {
   yield takeEvery(authenTypes.LOGIN_REQUEST, handleLoginSaga);
   yield takeEvery(authenTypes.SEND_MAIL_RESET_PASS, handleSendMailSaga);
   yield takeEvery(authenTypes.RESET_PASS, handleResetPassSaga)
}

export default handleAuthen;