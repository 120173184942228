import React from 'react';

const SignUp1 = React.lazy(() => import('./Demo/Authentication/SignUp/SignUp1'));
const Signin1 = React.lazy(() => import('./Demo/Authentication/SignIn/SignIn1'));
const Signin = React.lazy(() => import('./containers/auth/signin'));
const Reset = React.lazy(() => import('./containers/auth/reset'));
const SendMailReset = React.lazy(() => import('./containers/auth/send-mail'));
const HomePage = React.lazy(() => import('./containers/home'));
const DetailSearch = React.lazy(() => import('./containers/detail_search'));


const route = [
    { path: '/', exact: true, name: 'HomePage', component: HomePage },
    //{ path: '/auth/signup-1', exact: true, name: 'Signup 1', component: SignUp1 },
    { path: '/auth/signin-1', exact: true, name: 'Signin1', component: Signin1 },
    { path: '/auth/signin', exact: true, name: 'Signin', component: Signin },
    { path: '/auth/new-pass/:token', exact: true, name: 'Reset', component: Reset },
    { path: '/auth/send-mail-reset', exact: true, name: 'SendMailReset', component: SendMailReset },
    //{ path: '/hdsd/:slug', exact: true, name: 'detail_search', component: DetailSearch }
];
export default route;