import { toast } from 'react-toastify';


export const toastError = error => {
        toast.error(error, { autoClose: 3000 })

}

export const toastSuccess = ( message, time = 2000 ) => {
        toast.success(message, { autoClose: time })

}
export const toastWarrning = (message, options = {}) => {
        toast.warning(message, { autoClose: 3000, ...options })

}