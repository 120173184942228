export const CHANNEL_FETCH_LIST = "CHANNEL_FETCH_LIST";
export const CHANNEL_FETCH_LIST_SUCCESS = "CHANNEL_FETCH_LIST_SUCCESS";

export const CHANNEL_FETCH_ITEM_BY_ID = "CHANNEL_FETCH_ITEM_BY_ID";
export const CHANNEL_FETCH_ITEM_BY_ID_SUCCESS = "CHANNEL_FETCH_ITEM_BY_ID_SUCCESS";

export const CHANNEL_CREATE_UPDATE = "CHANNEL_CREATE_UPDATE";
export const CHANNEL_CREATE_UPDATE_SUCCESS = "CHANNEL_CREATE_UPDATE_SUCCESS";

export const CHANNEL_DELETE = "CHANNEL_DELETE";
export const CHANNEL_DELETE_SUCCESS = "CHANNEL_DELETE_SUCCESS";

export const CHANNEL_UPDATE = "CHANNEL_UPDATE";
export const CHANNEL_UPDATE_SUCCESS = "CHANNEL_UPDATE_SUCCESS";


export const CHANNEL_FETCH_BY_TYPE = "CHANNEL_FETCH_BY_TYPE";
export const CHANNEL_FETCH_BY_TYPE_SUCCESS = "CHANNEL_FETCH_BY_TYPE_SUCCESS";