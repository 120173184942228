import { combineReducers } from 'redux';

import { reducer as formReducer } from 'redux-form';
import ui from './ui';
import auth from './auth';
import user from './user';
import history from './history';
import device_type from './device_type';
import version from './version';
import manual from './manual';
import channel from './channel';


const rootReducer = combineReducers({
   ui,
   form: formReducer,
   auth,
   user,
   history, device_type, version, manual, channel
});

export default rootReducer;