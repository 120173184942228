export const DEVICE_TYPE_FETCH_LIST = "DEVICE_TYPE_FETCH_LIST";
export const DEVICE_TYPE_FETCH_LIST_SUCCESS = "DEVICE_TYPE_FETCH_LIST_SUCCESS";

export const DEVICE_TYPE_FETCH_ITEM_BY_ID = "DEVICE_TYPE_FETCH_ITEM_BY_ID";
export const DEVICE_TYPE_FETCH_ITEM_BY_ID_SUCCESS = "DEVICE_TYPE_FETCH_ITEM_BY_ID_SUCCESS";

export const DEVICE_TYPE_CREATE_UPDATE = "DEVICE_TYPE_CREATE_UPDATE";
export const DEVICE_TYPE_CREATE_UPDATE_SUCCESS = "DEVICE_TYPE_CREATE_UPDATE_SUCCESS";

export const DEVICE_TYPE_DELETE = "DEVICE_TYPE_DELETE";
export const DEVICE_TYPE_DELETE_SUCCESS = "DEVICE_TYPE_DELETE_SUCCESS";

export const DEVICE_TYPE_UPDATE = "DEVICE_TYPE_UPDATE";
export const DEVICE_TYPE_UPDATE_SUCCESS = "DEVICE_TYPE_UPDATE_SUCCESS";