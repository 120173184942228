import * as contant_types from '../contants/user';

export const handleFetchList = (requets = {}, token) => ({
    type: contant_types.USER_FETCH_LIST,
    payload: {
        requets,
        token
    }
})

export const handleFetchListSuccess = data => ({
    type: contant_types.USER_FETCH_LIST_SUCCESS,
    payload: {
        data
    }
})

export const handleFetchItemById = (id, token) => ({
    type: contant_types.USER_FETCH_ITEM_BY_ID,
    payload: {
        id,
        token
    }
})

export const handleFetchItemByIdSuccess = data => ({
    type: contant_types.USER_FETCH_ITEM_BY_ID_SUCCESS,
    payload: {
        data
    }
})

export const handleCreateUpdate = (requets = {}, token) => ({
    type: contant_types.USER_CREATE_UPDATE,
    payload: {
        requets,
        token
    }
})

export const handleCreateUpdateSuccess = data => ({
    type: contant_types.USER_CREATE_UPDATE_SUCCESS,
    payload: {
        data
    }
})

export const handleDelete = (id, token) => ({
    type: contant_types.USER_DELETE_ACCOUNT,
    payload: {
        id,
        token
    }
})

export const handleDeleteSuccess = data => ({
    type: contant_types.USER_DELETE_ACCOUNT_SUCCESS,
    payload: {
        data
    }
})


export const handleUpdate = (requets = {}, token) => ({
    type: contant_types.USER_UPDATE_ACCOUNT,
    payload: {
        requets,
        token
    }
})

export const handleUpdateSuccess = data => ({
    type: contant_types.USER_UPDATE_ACCOUNT_SUCCESS,
    payload: {
        data
    }
})