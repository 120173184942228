import React, { Component, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import Loadable from 'react-loadable';

import '../../node_modules/font-awesome/scss/font-awesome.scss';

import Loader from './layout/Loader'
import Aux from "../hoc/_Aux";
import ScrollToTop from './layout/ScrollToTop';
import routes from "../route";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import GlobalModal from '../components/GlobalModal';
import './layout/AdminLayout/app.scss';
import Footer from './layout/Footer';
const AdminLayout = Loadable({
    loader: () => import('./layout/AdminLayout'),
    loading: Loader
});

class App extends Component {
    render() {
        const menu = routes.map((route, index) => {
            return (route.component) ? (
                <Route
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    render={props => (
                        <route.component {...props} />
                    )} />
            ) : (null);
        });

        return (
            // <Aux>
            //     <ScrollToTop>
            //         <ToastContainer />
            //         <GlobalModal/>
            //         <Suspense fallback={<Loader />}>
            //             <Switch>
            //                 {menu}
            //                 <Route path="/" component={AdminLayout} />
            //             </Switch>
            //         </Suspense>
            //     </ScrollToTop>
            // </Aux>
            <Aux>
                <div>
                    <div className="pcoded-wrapper">
                        <div className="pcoded-content vh-90 vh-mb-80">
                            <div className="pcoded-inner-content">
                                <div className="main-body">
                                    <div className="page-wrapper">
                                        <Suspense fallback={<Loader />}>
                                            <Switch>
                                                {menu}
                                                <Route path="/" component={AdminLayout} />
                                            </Switch>
                                        </Suspense>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer/>
                    </div>
                   
                </div>
        </Aux>
        );
    }
}

export default App;
