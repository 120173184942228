import { createStore,compose, applyMiddleware } from 'redux';
import rootReducer from '../reducers';
import thunk from 'redux-thunk';
import rootSaga from '../sagas';
import createSagaMiddleware  from 'redux-saga';

const sagaMiddleware = createSagaMiddleware()


//window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()

const composeEnhancers = process.env.NODE_ENV !=='production' &&
typeof window ==='object' && 
window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__?
window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    shouldHotReload: false,
}):compose;

const configgureStore = () => {
    const middlewares = [thunk, sagaMiddleware];
    const enhacers = [applyMiddleware(...middlewares)];
    const store = createStore(rootReducer,composeEnhancers(...enhacers) )
    sagaMiddleware.run(rootSaga);
    return store;
};
export default configgureStore;