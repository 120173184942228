export const USER_FETCH_LIST = "USER_FETCH_LIST";
export const USER_FETCH_LIST_SUCCESS = "USER_FETCH_LIST_SUCCESS";

export const USER_FETCH_ITEM_BY_ID = "USER_FETCH_ITEM_BY_ID";
export const USER_FETCH_ITEM_BY_ID_SUCCESS = "USER_FETCH_ITEM_BY_ID_SUCCESS";

export const USER_CREATE_UPDATE = "USER_CREATE_UPDATE";
export const USER_CREATE_UPDATE_SUCCESS = "USER_CREATE_UPDATE_SUCCESS";

export const USER_DELETE_ACCOUNT = "USER_DELETE_ACCOUNT";
export const USER_DELETE_ACCOUNT_SUCCESS = "USER_DELETE_ACCOUNT_SUCCESS";

export const USER_UPDATE_ACCOUNT = "USER_UPDATE_ACCOUNT";
export const USER_UPDATE_ACCOUNT_SUCCESS = "USER_UPDATE_ACCOUNT_SUCCESS";