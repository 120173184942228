import * as contant_types from '../contants/history';

export const handleFetchList = (requets = {}, token) => ({
    type: contant_types.HISTORY_FETCH_LIST,
    payload: {
        requets,
        token
    }
})

export const handleFetchListSuccess = data => ({
    type: contant_types.HISTORY_FETCH_LIST_SUCCESS,
    payload: {
        data
    }
})

