import axios from 'axios';
import { toastError, toastSuccess } from '../helpers/toastHelpers';

class AxiosService {

    constructor() {
        const instace = axios.create();
        instace.interceptors.response.use(this.handleSuccess, this.handleError);

        this.instace = instace;
    }

    handleSuccess(responce) {
        return responce;
    }
    handleError(error) {
        console.log('error', error)
        const { message } = error;
        //const { message } = data;
        toastError(message);
        return error;
    }

    get(url, config = null) {
        return this.instace.get(url, config);
    }

    post(url, data, config = null) {
        return this.instace.post(url, data, config);
    }

    push(url, data) {
        return this.instace.put(url, data);
    }

    delete(url, config = null) {
        return this.instace.delete(url, config)
    }

    patch(url, data, config = null) {
        return this.instace.patch(url, data, config);
    }
}

export default new AxiosService();
