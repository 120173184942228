import * as conatant_types from '../contants/manual';
import { toastError, toastSuccess } from '../helpers/toastHelpers';
const initialState =
{
    data_table_manual: [],
    data_pagination_manual: 0,
    data_table_manual_run: [],
    data_pagination_manual_run: 0,
    data_create: null,
    initialValues: null,
    data_autocomplete: [],
    detailSearch: null,
    isLoading: true,
    isLoadingDetail: true
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case conatant_types.MANUAL_SEARCH_HDSD_SUCCESS: {
            const { data } = action.payload;
            console.log('data', data)
            return {
                ...state,
                isLoading: false,
                initialValues: null,
                data_table_manual: data.data,
                data_pagination_manual: data.count.length > 0 ? data.count[0].count : null
            };
        }
        case conatant_types.MANUAL_FETCH_LIST_RUN_SUCCESS: {
            const { data } = action.payload;
            console.log(data)
            return {
                ...state,
                data_table_manual_run: data.data,
                data_pagination_manual_run: data.count.length > 0 ? data.count[0].count : null
            };
        }
        case conatant_types.MANUAL_FETCH_ITEM_BY_ID_SUCCESS: {
            const { data } = action.payload;
            return {
                ...state,
                initialValues: data
            };
        }
        case conatant_types.MANUAL_CREATE_UPDATE_SUCCESS: {
            const { data } = action.payload;
            const { status } = data;
            if (!status) {
                toastError(data.message)
            } else {
                toastSuccess("Tạo thông tin thành công.")
                return { ...state, data_create: data };
            }
            return { ...state };
        }
        case conatant_types.MANUAL_DELETE_SUCCESS: {
            const { data } = action.payload;
            console.log(data)
            const { status } = data;
            if (!status) {
                toastError(data.message)
            } else {
                const { resp } = data;
                toastSuccess("Đã xóa thành công.")
                let { data_table_manual } = state;
                const index = data_table_manual.findIndex(item => item._id == resp._id);
                data_table_manual.splice(index, 1);
                return { ...state, data_table_manual: [...data_table_manual] };
            }
            console.log('data', data)
            return { ...state };
        }
        case conatant_types.MANUAL_UPDATE_SUCCESS: {
            const { data } = action.payload;
            const { status } = data;
            if (!status) {
                toastError(data.message)
            } else {
                toastSuccess("Thay đổi thông tin thành công.")

            }
            return {
                ...state
            };
        }
        case conatant_types.MANUAL_PAYMENT_SUCCESS: {
            const { data } = action.payload;
            const { status } = data;
            if (!status) {
                toastError(data.message)
            } else {
                toastSuccess("Thay đổi thông tin thành công.")
                let { data_table_manual } = state;
                let index = data_table_manual.findIndex(x => x._id == data.resp._id);
                if (index !== -1) {
                    data_table_manual[index].payment = true;
                    console.log(data_table_manual)
                    return { ...state, data_table_manual: [...data_table_manual] };
                }
                return { ...state };

            }
            return {
                ...state
            };
        }
        case conatant_types.MANUAL_AUTOCOMPLETE_SEARCH_SUCCESS: {
            const { data } = action.payload;
            const { result } = data;
            return {
                ...state,
                data_autocomplete: result
            };
        }
        case conatant_types.MANUAL_FETCH_ITEM_BY_SLUG_SUCCESS: {
            const { data } = action.payload;
           // const { result } = data;
            return {
                ...state,
                detailSearch: data,
                isLoadingDetail: false
            };
        }
        default:
            return state;
    }
};

export default reducer;
