export const VERSION_FETCH_LIST = "VERSION_FETCH_LIST";
export const VERSION_FETCH_LIST_SUCCESS = "VERSION_FETCH_LIST_SUCCESS";

export const VERSION_FETCH_ITEM_BY_ID = "VERSION_FETCH_ITEM_BY_ID";
export const VERSION_FETCH_ITEM_BY_ID_SUCCESS = "VERSION_FETCH_ITEM_BY_ID_SUCCESS";

export const VERSION_CREATE_UPDATE = "VERSION_CREATE_UPDATE";
export const VERSION_CREATE_UPDATE_SUCCESS = "VERSION_CREATE_UPDATE_SUCCESS";

export const VERSION_DELETE = "VERSION_DELETE";
export const VERSION_DELETE_SUCCESS = "VERSION_DELETE_SUCCESS";

export const VERSION_UPDATE = "VERSION_UPDATE";
export const VERSION_UPDATE_SUCCESS = "VERSION_UPDATE_SUCCESS";


export const VERSION_FETCH_BY_TYPE = "VERSION_FETCH_BY_TYPE";
export const VERSION_FETCH_BY_TYPE_SUCCESS = "VERSION_FETCH_BY_TYPE_SUCCESS";