export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_FALSE = "LOGIN_FALSE";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";

export const SEND_MAIL_RESET_PASS = "SEND_MAIL_RESET_PASS";
export const SEND_MAIL_RESET_PASS_SUCCESS = "SEND_MAIL_RESET_PASS_SUCCESS";

export const RESET_PASS = "RESET_PASS";
export const RESET_PASS_SUCCESS = "RESET_PASS_SUCCESS";

export const LOGOUT = "LOGOUT";
export const CHECK_TOKEN_LOGIN = "CHECK_TOKEN_LOGIN";
