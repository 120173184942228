import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Footer.css'; // Tùy chỉnh CSS nếu cần

const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className='pt-2 '>
          <Col md={1} >
            <a href='https://www.vinaorganic.com/gioi-thieu-vinaorganic' target='_blank'>Giới thiệu</a>
          </Col>
          <Col md={2} className='text-left text-lg-center'>
            <a href='https://www.vinaorganic.com/lien-he' target='_blank'>Liên hệ</a>
          </Col>

          <Col md={2}>
            <a href='https://www.vinaorganic.com/chinh-sach-bao-mat-thong-tin' target='_blank'>Bảo mật thông tin</a>
          </Col>
          <Col md={3} >
            <a href='https://www.vinaorganic.com/chinh-sach-bao-hanh' target='_blank'>Tra cứu - Chính sách bảo hành</a>
          </Col>
          <Col md={2} >
            <a href='https://www.vinaorganic.com/chinh-sach-doi-tra-hang' target='_blank'>Chính sách Đổi trả</a>
          </Col>
          <Col md={2} >
            <a href='https://www.vinaorganic.com/chinh-sach-thanh-toan' target='_blank'>Chính sách thanh toán</a>
          </Col>
        </Row>
        <hr className='mt-1 mb-1' />
        <Row>
          <Col>
            <p className=' mb-1 text-center'>© 2014-2024 VinaOrganic - Chế tạo máy thiết bị và Chuyển giao Công nghệ thực phẩm chuyên nghiệp.</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
