import { fork } from 'redux-saga/effects';
import handleAuthen from './auth';
import handleUser from './user';
import handleHistory from './history';
import handleDevice_type from './device_type';
import handleManual from './manual';
import handleChannel from './channel';



import handleVersion from './version'
function* rootSaga() {
    try {
        yield fork(handleDevice_type);
        yield fork(handleAuthen);
        yield fork(handleUser);
        yield fork(handleHistory);
        yield fork(handleVersion);
        yield fork(handleManual);
        yield fork(handleChannel);
    } catch (error) {
        console.log('error server!!!!!')
    }
}

export default rootSaga; 