import * as authenTypes from '../contants/auth';

export const handleLogin = ({ email, password }) => ({
    type: authenTypes.LOGIN_REQUEST,
    payload: {
        email,
        password
    }
})

export const handleLoginSuccess = data => {
    return {
        type: authenTypes.LOGIN_SUCCESS,
        payload: {
            data
        }
    }
}
export const handleLoginFailed = error => {
    return {
        type: authenTypes.LOGIN_FALSE,
        payload: {
            error
        }
    }
}
export const handleSendMailReset = ({ email }) => ({
    type: authenTypes.SEND_MAIL_RESET_PASS,
    payload: {
        email
    }
})

export const handleSendMailResetSuccess = data => {
    return {
        type: authenTypes.SEND_MAIL_RESET_PASS_SUCCESS,
        payload: {
            data
        }
    }
}
export const handleResetPass = ( token, password ) => ({
    type: authenTypes.RESET_PASS,
    payload: {
        token, password
    }
})

export const handleResetPassSuccess = data => {
    return {
        type: authenTypes.RESET_PASS_SUCCESS,
        payload: {
            data
        }
    }
}
export const handleLogout = () => {
    return {
        type: authenTypes.LOGOUT
    }
}

export const checkTokenLogin = () => ({
    type: authenTypes.CHECK_TOKEN_LOGIN
})
