import * as uiTypes from '../contants/ui';

export const onCollapseToggle = (id, type) => ({
    type: uiTypes.COLLAPSE_TOGGLE,
    payload: {
       id, type
    }
})
export const onNavCollapseLeave = (id, type) => ({
    type: uiTypes.NAV_COLLAPSE_LEAVE,
    payload: {
        id, type
     }
})
export const onFullScreenExit = () => ({
    type: uiTypes.FULL_SCREEN_EXIT
})
export const onComponentWillMount = () => ({
    type: uiTypes.COLLAPSE_MENU
})

export const show_modal = () => ({
    type: uiTypes.SHOW_MODAL
})

export const load_comp_modal = (requests = {}, component, config = {}) => ({
    type: uiTypes.LOAD_COMPONENT_MODAL,
    payload: {
        requests, component, config
    }
})

export const hide_modal = () => ({
    type: uiTypes.HIDE_MODAL
})

