import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import * as uiActions from '../../actions/ui';
import Modal from 'react-bootstrap/Modal';
class index extends Component {
    constructor(props) {
        super(props)
    }
    handleClose = () => {
        const { uiActionCreatetor } = this.props;
        const { hide_modal } = uiActionCreatetor;
        hide_modal();
    }

    render() {

        const { isShowModal, ComponentState, classes, config } = this.props;
        let xhtml = null;
        if (isShowModal) {
            xhtml = (
                <Modal
                    dialogClassName={config.dialogClassName ? config.dialogClassName : ''}
                    size={config.size ? config.size : ''}
                    fullscreen={config.maxWidth ? config.maxWidth : ''}
                    show={isShowModal}
                    onHide={this.handleClose}
                    backdrop={config.static ? config.static : "static"}
                    keyboard={false}
                >
                    {ComponentState}
                </Modal>
            )
        }
        return xhtml;
    }
}


const mapStateToProps = state => {
    return {
        isShowModal: state.ui.isShowModal,
        ComponentState: state.ui.ComponentState,
        config: state.ui.config,

    }
}
const mapDispatchToProps = dispatch => {
    return {
        uiActionCreatetor: bindActionCreators(uiActions, dispatch),
    }
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(
    withConnect)
    (index);
