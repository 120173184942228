import * as contant_types from '../contants/versions';

export const handleFetchList = (requets = {}, token) => ({
    type: contant_types.VERSION_FETCH_LIST,
    payload: {
        requets,
        token
    }
})

export const handleFetchListSuccess = data => ({
    type: contant_types.VERSION_FETCH_LIST_SUCCESS,
    payload: {
        data
    }
})

export const handleFetchItemById = (id, token) => ({
    type: contant_types.VERSION_FETCH_ITEM_BY_ID,
    payload: {
        id,
        token
    }
})

export const handleFetchItemByIdSuccess = data => ({
    type: contant_types.VERSION_FETCH_ITEM_BY_ID_SUCCESS,
    payload: {
        data
    }
})

export const handleCreateUpdate = (requets = {}, token) => ({
    type: contant_types.VERSION_CREATE_UPDATE,
    payload: {
        requets,
        token
    }
})

export const handleCreateUpdateSuccess = data => ({
    type: contant_types.VERSION_CREATE_UPDATE_SUCCESS,
    payload: {
        data
    }
})

export const handleDelete = (id, token) => ({
    type: contant_types.VERSION_DELETE,
    payload: {
        id,
        token
    }
})

export const handleDeleteSuccess = data => ({
    type: contant_types.VERSION_DELETE_SUCCESS,
    payload: {
        data
    }
})


export const handleUpdate = (requets = {}, token) => ({
    type: contant_types.VERSION_UPDATE,
    payload: {
        requets,
        token
    }
})

export const handleUpdateSuccess = data => ({
    type: contant_types.VERSION_UPDATE_SUCCESS,
    payload: {
        data
    }
})



export const handleFetchVsByType = (type) => ({
    type: contant_types.VERSION_FETCH_BY_TYPE,
    payload: {
        type
    }
})

export const handleFetchVsByTypeSuccess = data => ({
    type: contant_types.VERSION_FETCH_BY_TYPE_SUCCESS,
    payload: {
        data
    }
})