import * as actionTypes from '../contants/ui';
import config from './../config';

const initialState = {
    isOpen: [], //for active default menu
    isTrigger: [], //for active default menu, set blank for horizontal
    ...config,
    isFullScreen: false, // static can't change
    isShowModal: false,
    ComponentState: null,
    config: {},
};

const reducer = (state = initialState, action) => {
    let trigger = [];
    let open = [];

    switch (action.type) {
        case actionTypes.COLLAPSE_MENU: {
            return {
                ...state,
                collapseMenu: !state.collapseMenu
            };
        }
        case actionTypes.COLLAPSE_TOGGLE: {
            const { type, id } = action.payload;
            if (type === 'sub') {
                open = state.isOpen;
                trigger = state.isTrigger;

                const triggerIndex = trigger.indexOf(id);
                if (triggerIndex > -1) {
                    open = open.filter(item => item !== id);
                    trigger = trigger.filter(item => item !== id);
                }

                if (triggerIndex === -1) {
                    open = [...open, id];
                    trigger = [...trigger, id];
                }
            } else {
                open = state.isOpen;
                const triggerIndex = (state.isTrigger).indexOf(id);
                trigger = (triggerIndex === -1) ? [id] : [];
                open = (triggerIndex === -1) ? [id] : [];
            }

            return {
                ...state,
                isOpen: open,
                isTrigger: trigger
            };
        }
        case actionTypes.NAV_CONTENT_LEAVE:
            return {
                ...state,
                isOpen: open,
                isTrigger: trigger,
            };
        case actionTypes.NAV_COLLAPSE_LEAVE: {
            const { type, id } = action.payload;
            if (type === 'sub') {
                open = state.isOpen;
                trigger = state.isTrigger;

                const triggerIndex = trigger.indexOf(id);
                if (triggerIndex > -1) {
                    open = open.filter(item => item !== id);
                    trigger = trigger.filter(item => item !== id);
                }
                return {
                    ...state,
                    isOpen: open,
                    isTrigger: trigger,
                };
            }
            return { ...state };
        }
        case actionTypes.FULL_SCREEN:
            return {
                ...state,
                isFullScreen: !state.isFullScreen
            };
        case actionTypes.FULL_SCREEN_EXIT:
            return {
                ...state,
                isFullScreen: false
            };
        case actionTypes.CHANGE_LAYOUT:
            return {
                ...state,
                layout: action.layout
            };

        case actionTypes.SHOW_MODAL:
            return {
                ...state, isShowModal: true
            }
        case actionTypes.HIDE_MODAL:
            return {
                ...state, isShowModal: false, ComponentState: null
            }
        case actionTypes.LOAD_COMPONENT_MODAL:
            const { requests, component, config } = action.payload;
            return {
                ...state, ComponentState: component, config
            }
        default:
            return state;
    }
};

export default reducer;