import { call, put, takeEvery } from 'redux-saga/effects';
import { STATUS_CODE } from '../contants';
import * as conatant_type from '../contants/versions';

import {
   handleFetchListSuccess,
   handleCreateUpdateSuccess,
   handleDeleteSuccess,
   handleFetchItemByIdSuccess,
   handleUpdateSuccess,
   handleFetchVsByTypeSuccess
} from '../actions/versions';

import { fetchLists, fetchListsPost, handleDeleteApi, handleUpdateApi, handleUploadFileApi } from '../apis/common';
let url_router_default = 'versions/'

function* handleFetchListSaga({ payload }) {
   const { requets, token } = payload;
   const resp = yield call(fetchListsPost, url_router_default, requets, token);
   const { data, status } = resp;
   if (status === STATUS_CODE.SUSSESS) {
      yield put(handleFetchListSuccess(data));
   }
}
function* handleFetchItemByIdSaga({ payload }) {
   const { id, token } = payload;
   const resp = yield call(fetchLists, url_router_default + id, token);
   const { data, status } = resp;
   if (status === STATUS_CODE.SUSSESS) {
      console.log(data)
      yield put(handleFetchItemByIdSuccess(data));
   }
}

function* handleCreateUpdateSaga({ payload }) {
   const { requets, token } = payload;
   const { form_data } = requets;
   console.log(form_data)
   const resp = yield call(handleUploadFileApi, url_router_default + 'create', form_data, token);
   const { data, status } = resp;
   if (status === STATUS_CODE.SUSSESS) {
      yield put(handleCreateUpdateSuccess(data));
   }
}
function* handleDeleteSaga({ payload }) {
   const { id, token } = payload;
   const resp = yield call(handleDeleteApi, url_router_default, id, token);
   const { data, status } = resp;
   if (status === STATUS_CODE.SUSSESS) {
      yield put(handleDeleteSuccess(data));
   }
}
function* handleUpdateItemSaga({ payload }) {
   const { requets, token } = payload;
   const { _id } = requets;
   let url = url_router_default + _id;
   const resp = yield call(handleUpdateApi, url, requets, token);
   const { data, status } = resp;
   if (status === STATUS_CODE.SUSSESS) {
      yield put(handleUpdateSuccess(data));
   }
}

function* handleFetchVsByTypeSaga({ payload }) {
   const { type } = payload;
   const resp = yield call(fetchLists, url_router_default + 'get-version/' + type, null);
   const { data, status } = resp;
   if (status === STATUS_CODE.SUSSESS) {
      console.log(data)
      yield put(handleFetchVsByTypeSuccess(data));
   }
}

function* handleVersion() {
   yield takeEvery(conatant_type.VERSION_FETCH_LIST, handleFetchListSaga);
   yield takeEvery(conatant_type.VERSION_FETCH_ITEM_BY_ID, handleFetchItemByIdSaga);

   yield takeEvery(conatant_type.VERSION_CREATE_UPDATE, handleCreateUpdateSaga);
   yield takeEvery(conatant_type.VERSION_DELETE, handleDeleteSaga);
   yield takeEvery(conatant_type.VERSION_UPDATE, handleUpdateItemSaga);
   yield takeEvery(conatant_type.VERSION_FETCH_BY_TYPE, handleFetchVsByTypeSaga);

}

export default handleVersion;