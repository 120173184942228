import { call, put, takeEvery } from 'redux-saga/effects';
import { STATUS_CODE } from '../contants';
import * as conatant_type from '../contants/history';

import {
   handleFetchListSuccess
} from '../actions/history';

import { fetchLists, fetchListsPost, handleDeleteApi, handleUpdateApi } from '../apis/common';

function* handleFetchListSaga({ payload }) {
   const { requets, token } = payload;
   const resp = yield call(fetchListsPost, 'history/', requets, token);
   const { data, status } = resp;
   if (status === STATUS_CODE.SUSSESS) {
      yield put(handleFetchListSuccess(data));
   }
}
function* handleAuthen() {
   yield takeEvery(conatant_type.HISTORY_FETCH_LIST, handleFetchListSaga);
}

export default handleAuthen;